<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form @submit.enter.prevent="handleSubmit(onSubmit)" v-model="valid">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="90vh">
                    <v-container style="height: 90vh">
                        <v-tabs class="mt-10" v-model="tab" center-active>
                            <v-tab>{{ $t('User') }}</v-tab>

                            <v-tabs-items v-model="tab">
                                <v-tab-item class="pb-10">
                                    <v-row class="d-flex flex-row-reverse mt-15">
                                        <v-col class="form-pic d-flex justify-center" cols="12" md="3">
                                            <v-avatar
                                                :color="form.picture ? '' : 'primary'"
                                                :class="form.picture ? '' : 'v-avatar-light-bg primary--text'"
                                                size="150">
                                                <v-img v-if="getPic" :src="getPic"></v-img>
                                                <span v-else class="display-1 font-weight-medium">{{
                                                    avatarText(form.name)
                                                }}</span>
                                                <v-file-input
                                                    name="photo"
                                                    v-show="!isDisabled"
                                                    @change="onFileInput"
                                                    v-model="file"
                                                    class="file"
                                                    hide-input></v-file-input>
                                            </v-avatar>
                                        </v-col>
                                        <v-col cols="12" md="9">
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    <ValidationProvider
                                                        :name="$t('Form.Name')"
                                                        rules="required"
                                                        v-slot="{ errors }">
                                                        <v-text-field
                                                            :error="errors[0] ? true : false"
                                                            :disabled="isDisabled"
                                                            v-model="form.name"
                                                            :label="$t('Form.Name')"
                                                            required
                                                            filled
                                                            hide-details="auto"
                                                            shaped></v-text-field>
                                                        <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <ValidationProvider
                                                        :name="$t('Form.Phone')"
                                                        rules="required|mobile-phone"
                                                        v-slot="{ errors }">
                                                        <v-text-field
                                                            :error="errors[0] ? true : false"
                                                            hide-details="auto"
                                                            :disabled="isDisabled"
                                                            filled
                                                            shaped
                                                            v-model="form.phone"
                                                            :label="$t('Form.Phone')"
                                                            name="tel"
                                                            required></v-text-field>
                                                        <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-6">
                                                <v-col cols="12" md="6">
                                                    <ValidationProvider
                                                        name="E-mail"
                                                        rules="required|email"
                                                        v-slot="{ errors }">
                                                        <v-text-field
                                                            :error="errors[0] ? true : false"
                                                            :disabled="isDisabled"
                                                            filled
                                                            shaped
                                                            v-model="form.email"
                                                            :label="$t('Form.Email')"
                                                            hide-details="auto"
                                                            required
                                                            :error-messages="
                                                                emailError ? [$t('EmailAlreadyExists')] : []
                                                            ">
                                                        </v-text-field>
                                                        <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-select
                                                        :items="genders"
                                                        :item-text="'text'"
                                                        :item-value="'id'"
                                                        :disabled="isDisabled"
                                                        v-model="selectedGender"
                                                        filled
                                                        attach
                                                        :label="$t('Form.Gender')"></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-tabs>
                    </v-container>
                </v-sheet>
                <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
                    <v-btn @click="$router.go(-1)" class="square-button mx-3" color="grey-light">
                        <v-icon large color="grey">
                            {{ mdiChevronLeft }}
                        </v-icon>
                    </v-btn>

                    <v-toolbar-title v-if="!addUser">
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('UserProfile') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('UserProfile') }}
                        </p>
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('AddUser') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('AddUser') }}
                        </p>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                    <div v-if="!addUser">
                        <v-btn type="submit" v-show="!isDisabled" :loading="isLoading" class="mx-3" color="primary">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="ehite">
                                {{ mdiCheck }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Save') }}</span>
                        </v-btn>
                        <v-btn v-show="!isDisabled" @click="isDisabled = true" outlined class="mx-3" color="error">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Cancel') }}</span>
                        </v-btn>
                        <v-btn @click="editInformation" v-show="isDisabled" class="mx-3" color="grey-light">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Edit') }}</span>
                        </v-btn>

                        <v-dialog transition="dialog-top-transition" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-show="isDisabled" class="mx-3" color="grey-light" v-bind="attrs" v-on="on">
                                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>
                                <v-card>
                                    <v-card-text class="text-center">
                                        <p class="title font-weight-semibold black--text mt-12">
                                            {{ $t('Alerts.Sure') }}
                                        </p>
                                    </v-card-text>
                                    <v-card-text class="text-center">
                                        <p class="body-2">
                                            {{ $t('Alerts.DeleteUser') }}
                                            <strong class="black--text">{{ form.name }}</strong> ?<br />
                                            {{ $t('Alerts.Irreversible') }}
                                        </p>
                                    </v-card-text>

                                    <v-card-actions class="justify-end mt-4">
                                        <v-btn @click="deleteUser" color="error" class="px-5">
                                            <v-icon> {{ mdiTrashCanOutline }} </v-icon>
                                            {{ $t('Buttons.Delete') }}</v-btn
                                        >
                                        <v-btn text @click="dialog.value = false">{{ $t('Buttons.Cancel') }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </div>
                    <v-btn v-else type="submit" :loading="isLoading" class="mx-3" color="primary">
                        <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Add') }}</span>
                    </v-btn>
                </v-app-bar>
            </v-form>
        </ValidationObserver>
        <!-- ALERTAS DE SUCESSO E ERRO A ELIMINAR PARQUES-->
        <v-snackbar :timeout="2000" top v-model="isAddSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">Sucesso!</p>
            <p class="text-center black--text">Utilizador adicionado com sucesso.</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isUpdateSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">Sucesso!</p>
            <p class="text-center black--text">Utilizador atualizado com sucesso.</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isDeleteSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">Sucesso!</p>
            <p class="text-center black--text">Utilizador apagado com sucesso.</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">Erro!</p>
            <p class="text-center black--text">Ocorreu um erro. Tente novamente mais tarde.</p>
        </v-snackbar>
    </section>
</template>
<script>
    import {
        mdiChevronLeft,
        mdiFileDocumentEditOutline,
        mdiCheck,
        mdiClose,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiDeleteCircle,
        mdiMagnify,
    } from '@mdi/js';
    import userStoreModule from '../userStoreModule';
    import store from '@/store';
    import { onUnmounted, ref } from '@vue/composition-api';
    import useUsersList from '../user-list/useUsersList';
    import { avatarText } from '@core/utils/filter';
    import { uploadFile } from '@/api/upload';
    import { getLocations } from '@/api/location';
    import _ from 'lodash';
    // import { addUser } from '@/api/user';
    import { getRoles } from '@/api/roles.js';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    const initialState = () => ({
        isLoading: false,
        isLoadingTable: true,
        form: {
            name: '',
            email: '',
            username: '',
            picture: null,
            birthdate: '',
            gender: '',
            entity: [],
            bookings: [],
            role: '',
            favorites: [],
        },
        tab: 0,
        headers: [
            {
                text: '',
                sortable: true,
                value: 'name',
            },
            {
                text: '',
                sortable: true,
                value: 'email',
            },
            {
                text: '',
                sortable: true,
                value: 'district.name',
            },
            {
                text: '',
                sortable: true,
                value: 'county.name',
            },
            {
                text: '',
                sortable: true,
                value: 'parish.name',
            },
        ],
    });
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            ...initialState(),
            isDisabled: true,
            valid: false,
            /*  name: '',
            nif: '', */
            phone: '',
            nameRules: [
                (v) => !!v || 'Name is required',
                //  (v) => v.length <= 10 || 'Name must be less than 10 characters',
            ],
            /*  email: '', */
            emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+/.test(v) || 'E-mail must be valid'],
            address: '',

            file: null,
            countries: [],
            districts: [],
            counties: [],
            parishes: [],
            selectedCountry: '',
            selectedDistrict: '',
            selectedCounty: '',
            selectedParish: '',
            addUser: false,

            roles: [],
            dialog: false,
            genders: [
                { text: 'Masculino', value: 'M' },
                { text: 'Feminino', value: 'F' },
                { text: 'Outro', value: 'O' },
            ],
            selectedGender: '',
            isAddSuccess: false,
            isUpdateSuccess: false,
            isError: false,
            isDeleteSuccess: false,
            emailError: false,
        }),
        created() {
            this.headers[0].text = this.$t('Name');
            this.headers[1].text = this.$t('Email');
            this.headers[2].text = this.$t('District');
            this.headers[3].text = this.$t('County');
            this.headers[4].text = this.$t('Parish');
            if (this.$route.params.id != 'add') {
                this.addUser = false;
                this.isDisabled = true;
                this.getUser();
            } else {
                this.addUser = true;
                this.isDisabled = false;
            }
            //  this.getCountries();

            this.getRolesName();
        },
        setup() {
            const { resolveUserPictureMax } = useUsersList();
            return {
                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,
                mdiClose,
                mdiTrashCanOutline,
                mdiDeleteCircle,
                mdiPlus,
                mdiChevronLeft,
                resolveUserPictureMax,
                avatarText,
                mdiMagnify,
            };
        },
        computed: {
            getPic() {
                return this.resolveUserPictureMax(this.form.picture);
            },
        },
        methods: {
            editInformation() {
                this.isDisabled = !this.isDisabled;
            },
            getUser() {
                this.$store
                    .dispatch('app-user/fetchUser', this.$route.params.id)
                    .then((response) => {
                        this.form = response.data.data;
                        this.selectedCountry = _.get(this.form, 'country.id');
                        this.selectedDistrict = _.get(this.form, 'district.id');
                        this.selectedCounty = _.get(this.form, 'county.id');
                        this.selectedParish = _.get(this.form, 'parish.id');
                        this.selectedRole = _.get(this.form, 'role.id');
                        // this.selectedGender = _.get(this.userData, 'gender.text');

                        this.getDistricts();
                        this.getCounties();
                        this.getParishes();
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.form = {};
                        }
                    });
            },
            onSubmit() {
                if (this.addUser) this.onAddUser();
                else this.onUpdateUser();
            },
            onAddUser() {
                this.isLoading = true;

                let userData = _.assign({}, this.form);
                //username will be the user name + a random number with 10 digits
                userData.username =
                    userData.name + Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) + 1000000000;
                userData.gender = this.selectedGender.value;
                userData.birthdate = null;

                this.$store
                    .dispatch('app-user/addUser', userData)
                    .then((response) => {
                        this.isAddSuccess = true;
                        this.isLoading = false;
                        this.emailError = false;
                        setTimeout(() => {
                            this.$router.push({ name: 'UserList' });
                        }, 3000);
                    })
                    .catch((error) => {
                        this.isError = true;
                        this.isLoading = false;

                        if (error.response.data.error.message === 'Email already taken') {
                            this.emailError = true;
                        }

                        this.$toast.error(error.response.data.error.message);
                    });
            },
            onUpdateUser() {
                this.isLoading = true;

                let userData = _.assign({}, this.form);
                userData.role = this.selectedRole;
                userData.username = userData.email;
                userData.gender = this.selectedGender.value;
                userData.birthdate = null;

                this.$store
                    .dispatch('app-user/updateUser', userData)

                    .then((response) => {
                        this.isUpdateSuccess = true;
                        this.userData = response.data.data;
                        this.emailError = false;
                        this.isDisabled = true;
                        setTimeout(() => {
                            this.$router.push({ name: 'UserList' });
                        }, 3000);
                    })

                    .catch((error) => {
                        this.isError = true;

                        if (error.response.status === 404) {
                            this.userData = {};
                        }

                        if (
                            error.response.data.error.message === 'Email already taken' ||
                            error.response.data.error.message === `Username already taken: ${this.form.email}`
                        ) {
                            this.emailError = true;
                            this.isDisabled = false;
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            async onFileInput() {
                this.isLoading = true;
                await uploadFile(this.file)
                    .then((response) => {
                        this.form.picture = response.data[0];
                    })
                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            async getRolesName() {
                getRoles().then((response) => {
                    this.roles = response.data.roles;
                });
            },
            deleteUser() {
                this.$store
                    .dispatch('app-user/deleteUser', this.$route.params.id)
                    .then((response) => {
                        this.isDeleteSuccess = true;
                        setTimeout(() => {
                            this.$router.push({ name: 'UserList' });
                        }, 3000);
                    })
                    .catch((error) => {
                        this.isError = true;
                    });
            },
        },
    };
</script>
<style lang="scss" scoped>
    .is-icon {
        background-color: #0000ff;
        border-radius: 10px;
    }

    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }
</style>
